@import 'styles/layers.scss';

@layer component {
  .container {
    overflow-x: auto;
  }

  .td {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: unset !important;
  }

  .link, .link:visited, .link:hover {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
  }
}