@import 'styles/layers.scss';

@layer uikit {
  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }
  
  .tooltip {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  
  .counter {
    margin: 0;
    background-color: var(--orange10);
    padding: 4px 4px 4px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popover {
    border-radius: 6px;
    padding: 10px 14px;
    white-space: pre-wrap;
  }
  
  .popoverText {
    padding: 0;
    font-weight: 300;
    line-height: 25px;
  }
}