@import 'styles/layers.scss';

@layer component {
  .dark {
    color: var(--white);
  }

  .light {
    color: var(--black);
  }

  .title {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .text-block {
    width: 304px;
  }

  .block {
    margin-bottom: 30px;
  }

  .link {
    color: inherit;
    &:hover, &:active {
      color: inherit;
    }
  }

  .text-wrap {
    overflow-wrap: break-word;
  }

  .mt {
    margin-top: 6px;
  }

  .text {
    margin-bottom: 30px;
  }

  .full-width {
    width: 100%;
  }

  .link-mr {
    margin-right: 4px;
  }

  .note {
    font-size: 10px;
    line-height: 16px;
    margin-top: 6px;
  }

  .field-name, .note {
    opacity: 0.5;
  }

  .key-block {
    margin-top: 30px;
  }

  .spacer {
    height: 23px;
  }

  @media screen and (max-width: 468px) {
    .left {
      width: 250px;
    }
  }
}
