@import 'styles/layers.scss';

@layer component {
  .container {
    overflow-x: auto;
  }
  
  .content {
    min-width: 1326px;
  }
  
  .header {
    font-size: 14px;
    opacity: 0.3;
    margin-bottom: 12px;
  }
  
  .td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .next {
    margin-top: 30px;
  }
  
  .link {
    color: inherit;
    &:hover, &:active {
      color: inherit;
    }
  }
}