@import 'styles/layers.scss';

@layer uikit {
  .box {
    height: 30px;
    width: max-content;
    padding: 9px 14px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
    &.dark {
      background-color: var(--black28);
    }
    &.light {
      background-color: var(--grey9);
    }
  }
  
  .popover {
    border-radius: 6px;
    padding: 10px 14px;
    width: 198px !important;
  }
  
  .popoverText {
    padding: 0;
    line-height: 19.2px;
    font-weight: 300;
  }
  
  .bar {
    margin-bottom: 1px;
  }
  
  .barWrap {
    cursor: pointer;
  }
}