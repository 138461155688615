@import 'styles/layers.scss';

@layer component {
  .header {
    font-size: 20px;
    line-height: 30px;
  }

  .lines {
    margin-top: 10px;
    row-gap: 6px;
  }

  .key {
    opacity: 0.5;
  }
}
